import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import FileUploader from "./Pages/FileUploader";
import TikTokLogin from "./Pages/TikTokLogin";
import ChatPage from "./Pages/Chat";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TikTokLogin />} />
      </Routes>
    </Router>
  );
}

export default App;

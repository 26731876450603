import React, { useEffect, useState } from "react";

const TikTokLogin = () => {
  const [authCode, setAuthCode] = useState(null);

  useEffect(() => {
    // Récupérer le code d'autorisation depuis l'URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      setAuthCode(code);
    }
  }, []);

  const handleLogin = () => {
    window.location.href =
      "https://www.tiktok.com/v2/auth/authorize/?client_key=awp6osc6xl5b6g5c&response_type=code&scope=user.info.basic,user.info.profile,user.info.stats,video.list,video.publish,video.upload&redirect_uri=https%3A%2F%2Fmartinphlp.com&state=123456";
  };

  // Si nous avons un code d'autorisation, afficher celui-ci
  if (authCode) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
          <h2 className="text-xl font-bold mb-4">Authorization Successful!</h2>
          <div className="space-y-4">
            <p className="text-gray-600">
              Here is your authorization code. Copy it and use it in your local
              dashboard to start managing your TikTok account.
            </p>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-800 font-mono break-all">{authCode}</p>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-blue-800 text-sm">
                <strong>Instructions:</strong>
                <ol className="list-decimal ml-4 mt-2 space-y-1">
                  <li>Copy the code above</li>
                  <li>Open your local TikTok dashboard</li>
                  <li>
                    You can now close this page and start managing your TikTok
                    account !
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
        <div className="text-center mb-6">
          <img
            src="/logo.png"
            alt="Logo"
            className="h-40 mx-auto object-contain"
          />
          <h1 className="text-2xl font-bold mt-6">Connect with TikTok</h1>
        </div>

        <div className="space-y-6">
          <div className="text-center">
            <button
              onClick={handleLogin}
              className="bg-black hover:bg-gray-800 text-white font-semibold py-3 px-6 rounded-lg flex items-center justify-center w-full space-x-2"
            >
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z" />
              </svg>
              <span>Login with TikTok</span>
            </button>
          </div>

          <div className="space-y-4 text-sm text-gray-600">
            <div className="p-4 bg-gray-50 rounded-lg">
              <h3 className="font-semibold mb-2">Terms of Service</h3>
              <p className="text-justify">
                By connecting your TikTok account, you agree to use this
                dashboard for personal account management purposes only. This
                tool is designed for individual content creators to manage their
                own TikTok accounts and content. Any use of this tool for
                unauthorized purposes or to access third-party accounts without
                explicit permission is strictly prohibited.
              </p>
            </div>

            <div className="p-4 bg-gray-50 rounded-lg">
              <h3 className="font-semibold mb-2">Privacy Policy</h3>
              <p className="text-justify">
                We respect your privacy and handle your data with utmost care.
                The permissions requested are used solely for the purpose of
                managing your personal TikTok content and analytics. We do not
                store, share, or sell your personal information to any third
                parties. Your authentication tokens are encrypted and stored
                securely, and you can revoke access at any time through your
                TikTok settings.
              </p>
            </div>
          </div>
        </div>

        <div className="text-center text-xs text-gray-500 mt-6">
          <p>
            This is not an official TikTok product. This tool is intended for
            personal use only.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TikTokLogin;
